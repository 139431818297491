<template>
  <div v-if="!validatingToken">
    <div v-if="tokenValid" id="login-iframe">
      <iframe :src="azurePageUrl" width="100%" height="100%" frameborder="0"></iframe>
    </div>
    <div v-else>
      <div class="flex-container">
        <div class="not-found">
          <img class="not-found__image notfound-image" src="@/assets/img/logo_high_res.png" />
          <h2>{{ $t('message.connectAzureAuthentication.oldOrUnkownLink') }}</h2>
          <h3>{{ $t('message.connectAzureAuthentication.askApplicationManager') }}</h3>
          <a class="btn back-to-projects" href="https://www.terraindex.com">{{ $t('message.connectAzureAuthentication.backToTerraIndex') }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/configurations/config.js'
import cookie from 'vue-cookie'
import azureStateUtils from '@/utils/azureStateUtils'

const defaultLanguage = 'en' // default to English if no supported language code can be read

export default {
  name: 'Login',
  data() {
    return {
      tokenValid: true,
      validatingToken: true
    }
  },
  mounted: async function () {
    this.loadAndNormalizeLanguage()

    if (!this.queryToken) {
      this.redirectActions()
    } else {
      this.$store.dispatch('validateInviteToken', { inviteToken: this.queryToken }).then((validToken) => {
        this.tokenValid = validToken
        this.validatingToken = false

        if (!this.tokenValid) return
        this.redirectActions()
      })
    }
  },
  computed: {
    supportedLanguages() {
      return Object.keys(config.configLocales.messages)
    },
    currentLanguage() {
      return (cookie.get('lang') || defaultLanguage).toUpperCase()
    },
    azurePageUrl() {
      return this.redirectUrl()
    },
    queryToken() {
      return this.$route.query.invite_token
    }
  },
  methods: {
    redirectActions() {
      this.validatingToken = false
      // https://test.terraindex.com/Web/login?disableframe=true
      if (this.$route.query.disableframe) {
        this.redirect()
      }

      // using B2C login via an iFrame requires the URL to be specified in the B2C framework policy, e.g.
      // <JourneyFraming Enabled="true" Sources="https://web.terraindex.com https://test.terraindex.com" />
      // This only allows https URLs, so it can't be done locally, therefore, disable the iFrame when running
      // from localhost
      if (location.href.startsWith('http://localhost')) {
        this.redirect()
      }

      // override the URL of the outer page (not the iframe) with the main URL of the TI web application,
      // so the user always bookmarks the main URL until the login process is complete
      history.replaceState(history.state, '', config.apps.web)
    },
    // https://wiki.terraindex.com/bin/admin/XWiki/XWikiPreferences?editor=globaladmin&section=Localization
    languageFromTwoLettersToFourLetters() {
      switch (this.currentLanguage.toLowerCase()) {
        case 'nl':
          return 'nl_NL'
        case 'fr':
          return 'fr_FR'
        case 'en':
        default:
          return 'en'
      }
    },
    redirect() {
      location.href = this.redirectUrl()
    },
    loginUrl() {
      return this.addQueryParametersToRedirectUrl(config.sso.ssoUrl)
    },
    registerUrl() {
      return this.addQueryParametersToRedirectUrl(config.sso.registerUrl)
    },
    redirectUrl() {
      // if we have an invite token, the user came from the invite e-mail, so send him to the register page
      // otherwise, send him to the login page
      if (this.$route.query.invite_token) {
        return this.registerUrl()
      } else {
        return this.loginUrl()
      }
    },
    addQueryParametersToRedirectUrl(url) {
      url = azureStateUtils.setQueryParameter(url, 'lang', this.currentLanguage.toLowerCase())

      // package an existing redirect_url and/or invite_token into a state object that Azure will persist
      url = azureStateUtils.setQueryParameterAzureState(url, this.$route)

      return url
    },
    loadAndNormalizeLanguage() {
      let lang = cookie.get('lang')
      if (!lang || !this.supportedLanguages.includes(lang)) {
        let browserLocale = (cookie.get('language') || defaultLanguage).toLowerCase()
        let language = browserLocale.split('-')[0]
        if (this.supportedLanguages.includes(language)) {
          this.changeLanguage(language)
        } else {
          this.changeLanguage(defaultLanguage)
        }
      }
    },
    changeLanguage(languageCode) {
      cookie.set('lang', languageCode.toLowerCase())
      cookie.set('language', languageCode.toLowerCase())
      window.location.reload()
    }
  }
}
</script>

<style lang="less">
// Unscoped to hide Freshdesk help button.
#freshworks-container {
  display: none;
}

#login-iframe {
  position: absolute;
  overflow: hidden;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
}

.back-to-projects {
  margin-top: 10px;
  background-color: #67ac45;
  color: white;
  font-size: 15px;
}

.notfound-image {
  max-width: 15rem;
}
</style>
